<template>
  <v-container fluid class="d-flex-column mt-0 mb-0">
      <div class="ma-6">
          <v-card
            class="mx-auto"
            max-width="80%"
            tile
          >
            <v-alert
            :value="showBadge"
              type="error"
              prominent
              light
              border="left"
              transition="fade-transition"
            >
                  Failed to talk successfully to own API, failed to start!<br>
                  <p v-if="error"><br>{{error}}</p>
                  <v-btn color="primary" href="/">Go back to the app</v-btn>
            </v-alert>
          </v-card>
      </div>
  </v-container>
</template>
<script>
import he from "he";
  export default {
    name: 'ErrorView',
    props: [],
    data: () => ({
      error: false,
      showBadge: false
    }),
    components: {},
    mounted: function(){
      this.error =this.$route.query.error ?  he.decode(this.$route.query.error) : null;
      this.showBadge = true
    },
    computed: {
    }
  }
</script>

<style scoped>
</style>